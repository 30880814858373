import { Link, graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout.js"
import {listLink} from '../components/layout.module.css'



export default function PartOfSpeechTemplate({data}) {
    return (
    <Layout pageTitle={data.mdx.frontmatter.partOfSpeech}>
        <div>
        {data.PartOfSpeechInGroups.nodes.map(node => (
                  <p className={listLink}>
                     <Link to={"/" + node.frontmatter.slug}>
                       {node.frontmatter.title}</Link>
                  </p>
                ))
                }
      </div>
        </Layout>
  )
}

export const query = graphql`
query PartOfSpeechInfo($PartOfSpeechLabel: String) {
    mdx (frontmatter: {partOfSpeechSlug: {eq: $PartOfSpeechLabel}}) {
        frontmatter {
          section
          sectionTitle
          chapterTitle
          title
          slug
          partOfSpeech
        }
      }
      PartOfSpeechInGroups: allMdx(
        filter: {frontmatter: {partOfSpeechSlug: {eq: $PartOfSpeechLabel}}}) {
          nodes {
            frontmatter {
              title
              slug
              partOfSpeech
            }
          }
      }
}
`